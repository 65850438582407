// ui
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Typography from '../../../Typography';
import { Caption } from '../index';
// styles
import '../style.scss';

const cn = block('price_block');

// -------------------------------------------------------------------

const IncludeOption = ({
  title,
  caption,
}) => {
  return (
    <div className={cn('include-options', { wrap: true })}>
      <Typography
        weight="medium"
        variant="body2"
        className={cn('include-options', { title: true })}
        color="secondary400"
      >
        {title}
      </Typography>
      <Caption text={caption} />
    </div>
  );
};

IncludeOption.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
};

export default memo(IncludeOption);
