import { bem } from '@lib/bem';
import ItemsTicker from '@ui/ItemsTickerNew';
import './ClientLogos.scss';
import Image from '@ui/Image';
import clsx from 'clsx';

const { block } = bem('client-logos');

const logos = ['teorema', 'sitronics', 'wa', 'zenit', 'gpn', 'lidval', 'tiptop', 'case', 'glandia', 'duplex', 'elmagroup', 'coopervision', 'gsp', 'nizfarm', 'gpm', 'rbi', 'wilorus', 'gasstrprom', 'nlmk', 'obvodniy24', 'ekoservice', '1officecompany', 'granit', 'ipsos', 'code', 'cp', 'erstvak', 'umber', 'baltika', 'valenta', 'nornikel'];

const logoComponents = logos.map((name) => {
  return (
    <Image
      src={`/images/client-logos/${name}.webp`}
      className="mx-3.5 h-8 sm:mx-3 md:mx-2.5"
      key={name}
      alt={`${name}_logo`}
    />
  );
});

const ClientLogos = ({ className }) => {
  return (
    <div {...block({}, clsx(className, 'relative my-12'))}>
      <ItemsTicker
        items={logoComponents}
      />
      <div className="absolute top-0 right-0 z-10 h-full w-[132px] bg-[linear-gradient(270deg,#FBFCFF_0%,rgba(251,252,255,0.72)_48%,rgba(251,252,255,0)_100%)]" />
      <div className="absolute top-0 left-0 z-10 h-full w-[132px] rotate-180 bg-[linear-gradient(270deg,#FBFCFF_0%,rgba(251,252,255,0.72)_48%,rgba(251,252,255,0)_100%)]" />
    </div>
  );
};

export default ClientLogos;
