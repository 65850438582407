import { createContext, useMemo, useState } from 'react';

// -----------------------------------------------------------

export const TariffContext = createContext({});

const INIT_PRICE_CONTEXT_VALUE = {
  tariff: '',
  employees: 0,
  centers: 0,
  additional: false,
};

const PriceContext = ({ children }) => {
  const [tariffData, setTariffData] = useState(INIT_PRICE_CONTEXT_VALUE);
  const value = useMemo(() => ({
    tariffData,
    setTariffData,
  }), [tariffData]);

  return (
    <TariffContext.Provider value={value}>
      {children}
    </TariffContext.Provider>
  );
};

export default PriceContext;
