import { bem } from '@lib/bem';
// styles
import { isNumeric } from '@lib/form-validators';
import PropTypes from 'prop-types';
import { memo, useCallback, useState } from 'react';
import StaticIcon from '@ui/StaticIcon';
import Button from '../../../Button';
import { TextInput } from '../../../FieldBuilder';
import '../style.scss';

const { element } = bem('price_block');

// -------------------------------------------------------------------------------

const ButtonsBlock = ({
  getCount,
  minCount,
}) => {
  const [count, setCount] = useState(minCount);

  const incrementCount = useCallback(() => {
    setCount((state) => state + 1);
    getCount(count + 1);
  }, [count, getCount]);

  const decrementCount = useCallback(() => {
    if (count - 1 >= minCount) {
      getCount(count - 1);
      setCount((state) => (state > 0 ? state - 1 : 0));
    }
  }, [count, getCount, minCount]);

  const handleTextChange = useCallback(
    (e) => {
      if (isNumeric(e.target.value)) {
        const number = e.target.value.startsWith('0')
          ? parseInt(e.target.value.substr(1), 10)
          : parseInt(e.target.value, 10);

        const countValue = Number.isNaN(number) ? minCount : number;

        setCount(countValue);
        getCount(countValue);
      } else if (!e.target.value) {
        setCount(0);
        getCount(0);
      }
    },
    [getCount, minCount],
  );

  const handleInputBlur = useCallback(() => {
    if (count < minCount) {
      setCount(minCount);
      getCount(minCount);
    }
  }, [count, getCount, minCount]);

  return (
    <div {...element('counts', { wrap: true })}>
      <TextInput
        {...element('counts', { field: true })}
        field={{
          value: count,
          name: 'cap',
          onChange: handleTextChange,
          onBlur: handleInputBlur,
        }}
        variant="underline"
      />
      <Button
        {...element('count-button')}
        text={<StaticIcon name="minus" />}
        onClick={decrementCount}
        reversed
        disabled={count <= minCount}
      />
      <div {...element('buttons-separator')} />
      <Button
        {...element('count-button')}
        text={<StaticIcon name="plus" />}
        onClick={incrementCount}
        reversed
      />
    </div>
  );
};

ButtonsBlock.propTypes = {
  getCount: PropTypes.func.isRequired,
  minCount: PropTypes.number.isRequired,
};

export default memo(ButtonsBlock);
