// constants
import { TARIFFS_CHECK_LIST } from '@assets/content/constants';
// styles
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Typography from '../../../Typography';
import '../style.scss';

const cn = block('price_block');

// -------------------------------------------------------------------------------

export const CheckListRow = ({
  item,
}) => (
  <Typography
    color="secondary400"
    className={cn('check-list', {
      row: true,
    })}
  >
    <span className={cn('check-list', { row: 'check' })} />
    <span className="inline-block">{item.name}</span>
  </Typography>
);

// -------------------------------------------------------------------------------

const CheckList = ({ list }) => TARIFFS_CHECK_LIST.map((item) => {
  if (list.includes(item.id)) {
    return <CheckListRow key={item.id} item={item} />;
  }
  return null;
});

CheckList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default memo(CheckList);
