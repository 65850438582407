import { PRICE_QUESTIONS } from '@assets/content/constants';
// constants
import { SUPPORT } from '@assets/content/text';
// styles
import { block } from 'bem-cn';
import StaticIcon from '@ui/StaticIcon';
import clsx from 'clsx';
import { memo, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import MediaQuery from '../../MediaQuery';
// ui
import Typography from '../../Typography';
import { Question } from './components';
import './style.scss';

const cn = block('price_support_block');

// -----------------------------------------------------------------

const SupportBlock = () => {
  const [id, setId] = useState(null);
  const questions = useStaticQuery(graphql`
  query QuestionQuery {
    allMarkdownRemark(
      filter: {frontmatter: {type: {eq: "question"}}}
    )
    {
      nodes {
        htmlAst
        id
        frontmatter{
          title
        }
      }
    }
  }
  `);

  const handleClick = (ids) => {
    if (ids === id) {
      setId(null);
    } else {
      setId(ids);
    }
  };

  return (
    <div className="flex flex-col items-center md:mx-4">
      <div className="w-full border-tertiary200 bg-white p-10 sm:px-3 sm:py-7 md:flex-col md:px-4 md:py-7 md-up:flex md-up:rounded-2xl md-up:border-[3px] lg:justify-between">
        <div className="lg-only:col-5 xl:w-[360px]">
          <Typography color="primary400">{SUPPORT}</Typography>
          <Typography weight="semibold" variant="header2" color="secondary400" className={cn('title')}>
            Ответы на вопросы
          </Typography>
          <MediaQuery greaterThan="md">
            <StaticIcon name="price-support" className={cn('icon')} />
          </MediaQuery>
        </div>
        <div className="xl:w-[680px]">
          <ul className={cn('questions', { list: true })}>
            {questions.allMarkdownRemark.nodes.map((question) => (
              <Question key={question.frontmatter.title} question={question} handleClick={() => handleClick(question.id)} id={id} />
            ))}
          </ul>
        </div>
      </div>
      <MediaQuery greaterThan="sm">
        <div className="my-10 flex flex-col items-center md:my-8">
          <div className={cn('footer').mix('md-lg:flex')}>
            <Typography color="secondary400" className="tracking-tighter">
              Остались еще вопросы? Свяжитесь с нами по адресу
            </Typography>
            <a className={cn('email').mix('md-up:ml-0.5')} href="mailto:support@waveservice.app">
              <Typography color="primary400" variant="link1">support@waveservice.app</Typography>
            </a>
          </div>
          <MediaQuery lessThan="lg">
            <StaticIcon name="price-support" className={cn('icon').mix(clsx('mt-5'))} />
          </MediaQuery>
        </div>
      </MediaQuery>
      <MediaQuery at="sm">
        <div className={cn('footer').mix(clsx('mt-7'))}>
          <Typography color="secondary400" center className="column tracking-tighter flex sm:flex-col sm:items-center">
            <span>Остались еще вопросы?</span>
            <span>Свяжитесь с нами по адресу</span>
          </Typography>
          <a className={cn('email')} href="mailto:support@waveservice.app">
            <Typography color="primary400" variant="link1">support@waveservice.app</Typography>
          </a>
          <StaticIcon name="price-support" className={cn('icon').mix(clsx('mt-5 mb-7'))} />
        </div>
      </MediaQuery>

    </div>
  );
};

export default memo(SupportBlock);
