// ui
import { ADDITIONAL_MODULE } from '@assets/content/text';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { memo, useMemo, useState } from 'react';
import Typography, { getTypographyClass } from '../../../Typography';
import { Caption } from '../index';
// constants
// styles
import '../style.scss';
import { CheckListRow } from './CheckList';

const cn = block('price_block');

// ---------------------------------------------------------------------------------

const uniqId = () => new Date().getTime();

// -------------------------------------------------------------------------------

export const CustomCheckBox = ({
  label,
  callbackValue,
}) => {
  const [check, setCheck] = useState(false);

  const handlerChecked = (event) => {
    setCheck(event.target.checked);
    callbackValue(event.target.checked);
  };

  const idCheckBox = useMemo(() => uniqId(), [label]);

  return (
    <>
      <input
        type="checkbox"
        id={idCheckBox}
        checked={check}
        onChange={handlerChecked}
        className={cn('check-box', { input: true })}
      />
      <label
        htmlFor={idCheckBox}
        className={getTypographyClass({
          color: 'secondary400',
        })}
      >
        {label}
      </label>
    </>
  );
};

// -------------------------------------------------------------------------------

const AdditionalModule = ({
  module,
  callbackValue,
  tariffName,
}) => {
  const isInclude = !(module ? module.price : null);
  return (
    <div className={cn('add-module', { wrap: true })}>
      <Typography
        variant="caption2"
        color="secondary100"
        className={cn('add-module', { title: true })}
        transform="uppercase"
      >
        {ADDITIONAL_MODULE}
      </Typography>
      {isInclude ? (
        <CheckListRow item={module} isActive />
      ) : (
        <>
          <CustomCheckBox label={module.name} callbackValue={callbackValue} />
          <Caption
            text={module.comment}
            className={cn('caption', { shift: true })}
          />
        </>
      )}
    </div>
  );
};

AdditionalModule.propTypes = {
  module: PropTypes.shape({
    price: PropTypes.number,
    name: PropTypes.string.isRequired,
    comment: PropTypes.string,
  }),
};

export default memo(AdditionalModule);
