import Button from '@ui/Button';
import { Modal } from '@ui/Modal';
import { block } from 'bem-cn';
import React from 'react';
import clsx from 'clsx';
import { offerData } from './lib';
import './style.scss';

const name = 'special-offer';
const cn = block('offer-modal');
const SpecialOfferModal = ({ open, onClose }) => {
  return (
    <Modal
      title={offerData.title}
      titleClassname="md-up:text-center"
      name={name}
      visible={open}
      onClose={onClose}
      className="w-[640px] sm:mt-auto sm:w-full sm:rounded-t-lg md:w-[552px] md:rounded-[16px]"
    >
      <div className="text-center sm:text-left">
        <div className="sm:mt-2 sm:flex">
          <span className="mr-[10px] text-xl font-semibold md-up:hidden">{offerData.title}</span>
          <p className={cn('subtitle').mix(clsx('md-up:mx-auto'))}>
            {offerData.subtitle}
          </p>
        </div>
        <p className="mt-[18px] text-secondary300">{offerData.description}</p>
        <a
          href={offerData.button.href}
          target="_blank"
          onClick={onClose}
        >
          <Button className="mt-3 w-full sm:mb-1 md:mb-3">{offerData.button.name}</Button>
        </a>
      </div>
    </Modal>
  );
};

export default SpecialOfferModal;
