import MarkDownViewer from '@ui/MarkdownViewer';
import { memo } from 'react';
import { block } from 'bem-cn';
import StaticIcon from '../../../StaticIcon';
import Typography from '../../../Typography';
import '../style.scss';

const cn = block('price_support_block');

// ---------------------------------------------------------------

const Question = ({ question, handleClick, id }) => {
  const open = id === question.id;
  return (
    <div>
      <li
        className="flex cursor-pointer justify-between gap-4 border-b border-tertiary300 py-3"
        onClick={handleClick}
      >
        <Typography
          color="secondary400"
          className={cn('question', { title: true })}
          weight="semibold"
          as="h3"
        >
          {question.frontmatter.title}
        </Typography>
        <StaticIcon name="chevron-down" className={open && 'rotate-180'} />
      </li>
      {open && (
        <MarkDownViewer className="my-3 text-secondary400">
          {question.htmlAst}
        </MarkDownViewer>
      )}
    </div>
  );
};

export default memo(Question);
