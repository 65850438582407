// styles
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { memo } from 'react';
import clsx from 'clsx';
import Typography from '../../../Typography';
import '../style.scss';

const cn = block('price_block');

// -------------------------------------------------------------------------------

const Price = ({
  value,
  currency,
  period,
  request,
}) => {
  const isPrice = Boolean(+value);
  return (
    <div className={cn('price', {
      wrap: true,
    })}
    >
      <Typography className={cn('price', { value: true }).mix(clsx('!text-4xl'))} variant="header3">
        {value.toLocaleString('ru-RU')}
      </Typography>
      {currency && (
        <Typography
          className={cn('price', {
            value: true,
            currency: true,
          }).mix(clsx('!text-4xl'))}
          variant="header3"
        >
          {currency}
        </Typography>
      )}
      {period && <Typography className="!text-secondary100 opacity-80" variant="body2">{`/${period}`}</Typography>}
    </div>
  );
};

Price.defaultProps = {
  currency: '',
  period: '',
  request: false,
};

Price.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currency: PropTypes.string,
  period: PropTypes.string,
  request: PropTypes.bool,
};

export default memo(Price);
